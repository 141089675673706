import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

const Accreditations = () => {
  const data = useStaticQuery(graphql`
    query AccreditationsComponentQuery {
      allSanityAccreditation(filter: {show: {eq: true}, title: {ne: "Pearson VUE"}}) {
        nodes {
          title
          link
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const accreditations = data.allSanityAccreditation.nodes
  const accreditationElements = accreditations.map(({title, link, logo}) => (
    <a key={title} href={link} target='_blank' rel='noreferrer'>
      <img src={logo.asset.url} alt={title} style={{ width: '10rem', height: '10rem' }}/>
    </a>
  ))

  return (
    <StyledAccreditations>
      <h3>Our Accreditations {`>`}</h3>
      <div className="images" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
        {accreditationElements}
      </div>
    </StyledAccreditations>
  )
}

const StyledAccreditations = styled.section`
  h3 {
    background-color: var(--primary-color);
    color: #f4f4f4;
    padding: 1.2rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    border-left: solid 28vw #3B3B3B;
  }

  div.images {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1500px;
    margin: auto;
    padding: 2.6rem 0;

    img {
      width: 13rem;
      object-fit: contain;
    }
  }
`

export default Accreditations
