import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Accreditations from "../components/Accreditations"
import ChooseYourCourse from "../components/home-page/ChooseYourCourse"
import HomeCardLinks from "../components/home-page/HomeCardLinks"
import LatestNews from "../components/LatestNews"
import LearningMethodButtons from "../components/home-page/LearningMethodButtons"
import Seo from "../components/Seo"
import Showcase from "../components/home-page/Showcase"
// import TestimonialsCarousel from "../components/home-page/TestimonialsCarousel"
import UpcomingCourses from "../components/UpcomingCourses"

const IndexPage = ({ data }) => {
  const showcaseSlides = data.allSanityShowcaseSlide.nodes
  const learningMethods = data.allSanityLearningMethod.nodes
  const studyFields = data.allSanityStudyField.nodes
  const homeLinks = data.allSanityPage.nodes

  return (
    <StyledIndexPage>
      <Seo 
        title='Home' 
        description={data.sanitySiteSettings.description}
        pathname="/"
      >
      </Seo>

      <Showcase slides={showcaseSlides} />
      <LearningMethodButtons learningMethods={learningMethods} />
      <ChooseYourCourse learningMethods={learningMethods} studyFields={studyFields} />
      <HomeCardLinks homeLinks={homeLinks}/>
      {/* <TestimonialsCarousel /> */}

      <Accreditations />

      <section className="component-group">
        <UpcomingCourses />
        <LatestNews />
      </section>
    </StyledIndexPage>
  )
}

export const query = graphql`
  query HomePageQuery {
    allSanityShowcaseSlide(sort: {fields: order}) {
      nodes {
        order
        backgroundImage {
          asset {
            gatsbyImageData
          }
        }
        mobileBackgroundImage {
          asset {
            gatsbyImageData
          }
        }
        link {
          internalLink {
            current
          }
          href
          linkType
        }
      }
    }  
    allSanityLearningMethod(sort: {fields: rank}) {
      nodes {
        title
        slug { current }
        themeColour
        shortDescription
      }
    }
    allSanityStudyField(sort: {fields: title, order: ASC}) {
      nodes {
        title
        slug { current }
      }
    }
    sanitySiteSettings {
      description
    }
    allSanityPage {
      nodes {
        title
        slug {
          current
        }
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }  

  }
`

const StyledIndexPage = styled.main`
  section.component-group {
    display: flex;
    flex-flow: row wrap;
    gap: 1.75rem;
    max-width: 1500px;
    margin: 2rem auto;
    padding: 0 1rem;

    & > * {
      flex: 1 1 400px;
    }
  }
`

export default IndexPage
