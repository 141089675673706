import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import * as styles from '../../styles/components/homecardlinks.module.scss'

const HomeCardLinks = ({ homeLinks }) => {
  const filteredLinks = homeLinks.filter(link => link.title === 'Our Team' || link.title === 'Our Campus');

  return (
    <StyledHomeCardLinks>
      {/* <Link to='/our-team/' className="card">
        <img src={defaultImage} alt="meet our team" />
        <h3>Meet Our Team</h3>
      </Link>
      <Link to='/our-campus/' className="card">
        <img src={defaultImage} alt="our campus" />
        <h3>Our Campus</h3>
      </Link> */}
      {filteredLinks.map(link => (
        <Link to={`/${link?.slug?.current}/`} key={link.slug.current} className="card">
          <GatsbyImage image={getImage(link.featuredImage.asset)} alt={link.title} className={styles.image}/>
          <h3>{link.title}</h3>
        </Link>
      ))}
    </StyledHomeCardLinks>
  )
}

const StyledHomeCardLinks = styled.section`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 6rem;

  a.card {
    text-decoration: none;

    img {
      margin-bottom: 1rem;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
    }
  }
`

export default HomeCardLinks
