import React from 'react'
import styled from 'styled-components'
import formatLearningMethodTitle from '../../helpers/formatLearningMethodTitle'
import { navigate } from 'gatsby'

const LearningMethodButtons = ({ learningMethods }) => {
  const handleFilterClick = (title) => {
    // Assuming you want to filter courses based on learning method
    const queryString = new URLSearchParams({ learningMethod: title }).toString();
    navigate(`/courses?${queryString}`);
  };

  return (
    <StyledLearningMethodButtons>
      {learningMethods.map((method) => (
        <button
          key={method.slug.current}
          onClick={() => handleFilterClick(method.title)}
          style={{ background: method.themeColour }}
        >
          {formatLearningMethodTitle(method.title)}
        </button>
      ))}
    </StyledLearningMethodButtons>
  );
};

// const LearningMethodButtons = ({ learningMethods}) => (
//   <StyledLearningMethodButtons>
//     {learningMethods.map(method => (
//       <Link key={method.slug.current} to={`/learning-methods/${method.slug.current}/`} style={{background: method.themeColour}}>
//         {formatLearningMethodTitle(method.title)}
//       </Link>
//     ))}
//   </StyledLearningMethodButtons>
// )

const StyledLearningMethodButtons = styled.section`
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 3rem;

  button {
    display: inline-block;
    background: var(--primary-color);
    color: #fafafa;
    width: 50vw;
    min-width: 200px;
    padding: 0.8em 0;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &.blue { background-color: #312668; }
    &.red { background-color: var(--primary-color); }
    &.orange { background-color: #F35302; }
    &.grey { background-color: #767676; }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    padding: 2rem 1rem;
    
    button {
      margin-bottom: .5rem;
      width: 100vw;
    }
  }

  @media (max-width: 480px) {
    gap: 1rem;
    padding: 2rem 1rem;

    a {
      font-size: 1.25rem;
    }
  }
`

export default LearningMethodButtons
